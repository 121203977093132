import {appConfig} from './app/app.config';
import {bootstrapApplication} from '@angular/platform-browser';
import {AppComponent} from './app/app.component';
import {registerLocaleData} from '@angular/common';
import localeEsCoExtra from '@angular/common/locales/extra/es-CO';
import locale_esCo from '@angular/common/locales/es-CO';

registerLocaleData(locale_esCo, 'es', localeEsCoExtra);

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
