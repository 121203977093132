/*
 * Project:      mkad-frontend
 * Developed by: Juan David Pelaez Cumbe
 * Date:         11/03/24 - 8:35
 * Module name:  main-router
 * File name:    main-router.guard.ts
 * IDE:          WebStorm
 */

import {CanActivateFn, Router} from '@angular/router';
import {inject} from '@angular/core';
import {UserLevelEnum} from '@zibanu/auth/lib/enums/user-level.enum';
import {PreferencesService} from '@shared/services';

export const RolesRouterGuard: CanActivateFn = (route) => {
  const role: UserLevelEnum = route.data['role'] ?? UserLevelEnum.Guest;
  const userPreferences = inject(PreferencesService);
  const router = inject(Router);
  if (role === UserLevelEnum.Guest) {
    router.navigate(['home']).then();
    return false
  }
  return userPreferences.level <= role;
};

