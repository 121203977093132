import {Component, OnInit} from '@angular/core';
import {ZbBaseComponent} from '@zibanu/ui';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {NgFor, NgOptimizedImage} from '@angular/common';
import {NbButtonModule, NbIconModule} from '@nebular/theme';
import {MkadCarouselComponent} from '@shared/components';
import {PANEL_CARD_CUSTOMER, PANEL_CARD_DEVICES, PANEL_CARD_SYSTEM} from '@shared/constants';



@Component({
  selector: 'mkad-panel',
  standalone:true,
  imports: [NgFor, NbButtonModule, NbIconModule, NgOptimizedImage, MkadCarouselComponent, TranslateModule],
  templateUrl: './panel.component.html',
  styleUrl: './panel.component.scss'
})
export class SettingsPanelComponent extends ZbBaseComponent implements OnInit{
  cardListCustomer = PANEL_CARD_CUSTOMER;
  cardListDevice = PANEL_CARD_DEVICES;
  cardListSystem = PANEL_CARD_SYSTEM;

  /**
   * @description
   * Constructor class
   * @param translateService TranslateService dependency injection
   */
  constructor(
    protected readonly translateService: TranslateService,
  ) {
    super(translateService);
    this.translate.onLangChange.subscribe(
      {
        next: () => {
          this.translateCardList();
        }
      }
    )
  }

  /**
   * @description
   * Method that response to OnInit
   */
  override ngOnInit() {
    super.ngOnInit();
    this.translateCardList();
  }
  /**
   * @description
   * Method that translates card list
   */
  translateCardList(){
    const customerTranslate=structuredClone([...PANEL_CARD_CUSTOMER])
    const deviceTranslate=structuredClone([...PANEL_CARD_DEVICES])
    const systemTranslate=structuredClone([...PANEL_CARD_SYSTEM])
    this.cardListCustomer = this.getTranslateMap(customerTranslate,['title']);
    this.cardListDevice = this.getTranslateMap(deviceTranslate,['title']);
    this.cardListSystem = this.getTranslateMap(systemTranslate,['title']);
  }

}
