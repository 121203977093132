/*
 * Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2024. All rights reserved.
 * Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2024. Todos los derechos reservados.
 */

/*
* Project:      frontend
* Developed by: Jhoana Marcela Rodriguez Fernandez
* Date:         5/3/2024 - 11:40
* Module name:  frontend
* File name:    settings.routes.ts
* IDE:          WebStorm
*/

import {Routes} from '@angular/router';
import SettingsPanelComponent from './panel';
import {MkadContractsService, MkadCustomersService} from '@shared/services';
import {headerTitle} from '@shared/utils';
import {UserLevelEnum} from '@zibanu/auth/lib/enums/user-level.enum';

export const SETTING_ROUTE_LIST: Routes = [
  {path: '', redirectTo: 'panel', pathMatch: 'prefix'},
  {
    path: 'panel',
    component: SettingsPanelComponent,
    data: {
      role: UserLevelEnum.Operator,
    },
  },
  {
    path: 'customers',
    title: headerTitle('Customers'),
    data: {
      role: UserLevelEnum.Staff,
    },
    loadComponent: () => import('./customer/customers/customers-list'),
    providers: [MkadCustomersService],
  },
  {
    path: 'contracts',
    data: {
      role: UserLevelEnum.SuperAdmin,
    },
    title: headerTitle('Contracts'),
    loadComponent: () => import('./customer/contracts/contracts-list'), providers: [MkadContractsService],
  },
  {
    path: 'contacts',
    data: {
      role: UserLevelEnum.Supervisor,
    },
    title: headerTitle('Contacts'),
    loadComponent: () => import('./customer/contacts/contacts-list'), providers: [],
  },
  {
    path: 'groups',
    data: {
      role: UserLevelEnum.Supervisor,
    },
    title: headerTitle('Group'),
    loadComponent: () => import('./customer/groups/groups-list'),
  },
  {
    path: 'users',
    data: {
      role: UserLevelEnum.Admin,
    },
    loadComponent: () => import('./customer/users/users-list'),
  },
  {
    path: 'locations',
    data: {
      role: UserLevelEnum.Admin,
    },
    loadComponent: () => import('./customer/locations/locations-list'),
  },
  {
    path: 'sensors',
    data: {
      role: UserLevelEnum.SuperUser,
    },
    loadComponent: () => import('./system/sensors/sensors-list'),
  },
  {
    data: {
      role: UserLevelEnum.Staff,
    },
    path: 'parameters',
    loadComponent: () => import('./system/parameters/parameters-list'),
  },
  {
    path: 'commands',
    data: {
      role: UserLevelEnum.Staff,
    },
    loadComponent: () => import('./system/commands/commands-list'),
  },
  {
    path: 'icons',
    data: {
      role: UserLevelEnum.Staff,
    },
    loadComponent: () => import('./system/icons/icons-list'),
  },
  {
    path: 'device-model',
    data: {
      role: UserLevelEnum.SuperUser,
    },
    loadComponent: () => import('./system/device-model/device-model-list'),
  },
  {
    path: 'devices',
    data: {
      role: UserLevelEnum.SuperUser,
    },
    loadComponent: () => import('./system/devices/devices-list'),
  },
  {
    path: 'triggers',
    data: {
      role: UserLevelEnum.Supervisor,
    },
    loadComponent: () => import('./device/triggers/triggers-list'),
  },
  {
    path: 'device-customization',
    data: {
      role: UserLevelEnum.Supervisor,
    },
    loadComponent: () => import('./device/customer-device/customer-device-list'),
  },
  {path: '**', redirectTo: 'panel', pathMatch: 'prefix'},
];
