/*
 Developed by CQ Inversiones SAS. Copyright ©. 2019 - 2024. All rights reserved.
 Desarrollado por CQ Inversiones SAS. Copyright ©. 2019 - 2024. Todos los derechos reservados.
 */

/*
 * Project:      mkad-frontend
 * Developed by: Juan David Pelaez Cumbe
 * Date:         27/04/24 - 18:27
 * Module name:  app.config
 * File name:    app.config.ts
 * IDE:          WebStorm
 */

import {ApplicationConfig, importProvidersFrom, LOCALE_ID} from '@angular/core';
import {provideRouter} from '@angular/router';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {provideAnimations} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbSidebarService,
  NbThemeModule,
  NbToastrModule,
  NbToastrService,
} from '@nebular/theme';
import {NbMomentDateModule} from '@nebular/moment';
import {NbDateFnsDateModule} from '@nebular/date-fns';
import {NbAuthJWTToken, NbAuthModule, NbPasswordAuthStrategy} from '@nebular/auth';
import {NbSecurityModule} from '@nebular/security';
import {NbEvaIconsModule} from '@nebular/eva-icons';
import {NgxZibanuLibModule, ZbHttpInterceptor, ZbSecureStorageService} from '@zibanu/lib';
import {NgxZibanuAuthModule, ZbAuthManagerService} from '@zibanu/auth';
import {NgxZibanuUiModule, ValidatorsManager} from '@zibanu/ui';
import {provideEcharts} from 'ngx-echarts';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {MkadBaseService, MkadMenuManagerService, PreferencesService, MkadUserCustomersService} from '@shared/services';
import {RetryManagerInterceptor, SessionManagerInterceptor} from '@shared/utils';
import {SETTING_TIMEOUT} from '@shared/constants';
import {environment} from '../environments/environment';
import {APP_ROUTES} from './app-routing.module';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

/**
 * nebular root module
 */
export const NEBULAR_MODULES = [
  NbThemeModule.forRoot({
    name: 'corporate',
  }),
  NbMomentDateModule,
  NbDateFnsDateModule.forRoot({
    format: 'dd-MM-yyy',
  }),
  NbAuthModule.forRoot({
    strategies: [
      NbPasswordAuthStrategy.setup(
        {
          name: 'email',
          baseEndpoint: <string>environment['apiUrl'],
          login: {
            endpoint: 'auth/xDQ4d7p7kkCK8VAA3/',
            method: 'post',
          },
          logout: {
            endpoint: 'auth/logout/',
            method: 'post',
          },
          refreshToken: {
            endpoint: 'auth/j6UvfcGuSCbKDK65R/',
            method: 'post',
          },
          token: {
            class: NbAuthJWTToken,
            key: 'token',
          },
          requestPass: {
            endpoint: 'auth/reset-password/',
            method: 'post',
          },
          resetPass: {
            endpoint: 'auth/change-password/',
            method: 'post',
          },
        },
      ),
    ],
  }),
  NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        view: ['news', 'comments'],
      },
      user: {
        parent: 'guest',
        create: 'comments',
      },
      moderator: {
        parent: 'user',
        create: 'news',
        remove: '*',
      },
    },
  }),
  NbMenuModule.forRoot(),
  NbSidebarModule.forRoot(),
  NbDatepickerModule.forRoot(),
  NbToastrModule.forRoot({
    duration: SETTING_TIMEOUT,
    toastClass: 'mkad_toast',
  }),
  NbDialogModule.forRoot({
    closeOnEsc: false,
    hasBackdrop: true,
    closeOnBackdropClick: false,
    hasScroll: false,
  }),
  NbEvaIconsModule
];

/**
 * zibanu modules
 */
export const ZIBANU_MODULES = [
  NgxZibanuLibModule.forRoot(
    {
      baseEndpoint: environment['apiUrl'] as string,
      tokenKeyword: 'JWT',
    },
  ),
  NgxZibanuAuthModule.forRoot(
    {
      urls: {
        group: {
          list: 'auth/role/list/',
        },
        level: {
          list: 'auth/group-level/list/',
        },
        user: {
          list: 'auth/user/list/',
          add: 'auth/user/add/',
          delete: 'auth/user/delete/',
          update: 'auth/update/',
          profile: 'auth/user/profile',
        },
      },
    },
  ),
  NgxZibanuUiModule.forRoot({
    validators: {
      password: {
        minLength: 8,
        hasCapital: true,
        hasNumber: true,
        specialChar: true,
      },
    },
    images: {
      background: 'https://picsum.photos/id/11/1920/2100',
      logo: '../assets/image/meerkad_light.webp',
    },
  }),
];

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(APP_ROUTES),
    provideAnimations(),
    provideEcharts(),
    importProvidersFrom(
      HttpClientModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: (HttpLoaderFactory),
          deps: [HttpClient],
        },
      }),
      LeafletModule,
      NgxDatatableModule,
      ...NEBULAR_MODULES,
      ...ZIBANU_MODULES,
    ),
    {
      provide: LOCALE_ID,
      useValue: 'es-CO',
    },
    NbSidebarService,
    NbToastrService,
    MkadBaseService,
    ValidatorsManager,
    PreferencesService,
    MkadUserCustomersService,
    ZbSecureStorageService,
    ZbAuthManagerService,
    MkadMenuManagerService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ZbHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionManagerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryManagerInterceptor,
      multi: true,
    },
  ],
};
