<nb-layout [nbSpinner]="isLoading" [nbSpinnerSize]="'giant'">
  <nb-layout-header fixed>
    <mkad-frame-header class="w-full h-full"
                       [name]="userName ? userName : userEmail"
                       [avatarUrl]="userAvatar"
                       [isActive]="isSessionActive"
                       [perpetualSession]="hasPerpetualSession()"
                       [showAvatarAlert]="dueDateAlert"
                       (clickToggleIcon)="sidebarMenu.toggle(true)" (clickAvatar)="onClickAvatar()"/>
  </nb-layout-header>
  <nb-sidebar fixed #sidebarMenu [tag]="sidebarMainTag" id="menu_left" [left]="true" [containerFixed]="false"
              state="compacted">
    @if (mainMenu$$ | async; as mainMenu) {
      <nb-menu [tag]="menuMainTag" [items]="mainMenu" (click)="sidebarMenu.compact()"/>
    }
  </nb-sidebar>
  <nb-layout-column>
    <router-outlet/>
  </nb-layout-column>
  <nb-layout-footer>
    <mkad-frame-footer class="w-full h-full" [politiesUrl]="politiesUrl['getUrl'](getCurrentLang())"
                       (clickLeftIcon)="openAboutUsDialog()" [customerData]="userCustomerData"/>
  </nb-layout-footer>
</nb-layout>

