import {AfterViewInit, Component, OnInit, signal, ViewChild, ViewContainerRef} from '@angular/core';
import {Router, RouterOutlet} from '@angular/router';
import {
  NbIconLibraries, NbLayoutColumnComponent,
  NbLayoutModule,
  NbMenuModule,
  NbSidebarModule,
  NbSpinnerModule,
} from '@nebular/theme';
import {TranslateService} from '@ngx-translate/core';
import {Meta} from '@angular/platform-browser';
import {
  catchError,
  EMPTY,
  filter,
  finalize,
  of,
  switchMap,
  tap,
} from 'rxjs';
import {icon, Marker} from 'leaflet';
import {ZbChangePasswordComponent, ZbLoginComponent} from '@zibanu/ui';
import {UserLevelEnum, ZbAuthManagerService} from '@zibanu/auth';
import {ZbSecureStorageService} from '@zibanu/lib';
import {AboutUsService, MkadMenuManagerService} from '@shared/services';
import {
  AppSettingsComponent, FrameFooterComponent,
  FrameHeaderComponent,
  MkadAboutUsComponent,
  UserProfileComponent,
} from '@shared/components';
import {
  DEFAULT_MAP_LATITUDE, DEFAULT_MAP_LONGITUDE,
  EXTRA_ICON_CONFIG,
  MENU_MAIN_TAG,
  SIDEBAR_MAIN_ID,
  THOUSAND,
} from '@shared/constants';
import {LanguageAvailableType} from '@shared/types';
import {AllowedLanguagesEnum} from '@shared/enums';
import {TERMS_CONDITIONS_CONST} from '@shared/utils';
import {MkadBaseComponent} from '@shared/components/base/mkad-base.component';
import {MkadUserCustomersService} from '@shared/services/settings/mkad-user-customers.service';
import {AsyncPipe} from '@angular/common';
import {MkadScrollControlService} from '@shared/services/main/mkad-scroll-control.service';

const sixty = 60;
const twentyFour = 24;
const fiftyDaysInSeconds = (THOUSAND * sixty * sixty * twentyFour);


/**
 * App component
 * @selector mkad-app
 * @extends MkadBaseComponent
 * @standalone False
 */
@Component({
  selector: 'mkad-app',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [NbLayoutModule, NbSidebarModule, NbSpinnerModule, NbMenuModule, FrameHeaderComponent, FrameFooterComponent, AsyncPipe, RouterOutlet],
  providers: [AboutUsService],
})
export class AppComponent extends MkadBaseComponent implements OnInit, AfterViewInit {
  isSessionActive = false;
  userName?: string;
  userAvatar?: string;
  userLevel = UserLevelEnum.Guest;
  politiesUrl = TERMS_CONDITIONS_CONST;
  mainMenu$$ = this.menuService.onChangeMainMenu();
  sidebarMainTag = SIDEBAR_MAIN_ID;
  menuMainTag = MENU_MAIN_TAG;
  private isFirstTokenChange = signal(true);
  @ViewChild(NbLayoutColumnComponent, { read: ViewContainerRef }) container!: ViewContainerRef;  //this is always undefined

  override toTranslate = {
    'zibanu.ui.login.message.success': '',
    'zibanu.ui.logout.message.success': ''
  };

  /**
   * @description
   * Constructor class
   * @param iconLibrary NbIconLibraries dependency injection
   * @param translate TranslateService dependency injection
   * @param meta Meta dependency injection
   * @param authService ZbAuthManagerService dependency injection
   * @param storage SecureStorageService dependency injection
   * @param menuService MkadMenuManagerService dependency injection
   * @param userCustomer MkadUserCustomerService dependency injection
   * @param route Router dependency injection
   * @param scrollControl MkadScrollControlService dependency injection
   */
  constructor(override translate: TranslateService,
              private readonly iconLibrary: NbIconLibraries,
              private readonly meta: Meta,
              private readonly authService: ZbAuthManagerService,
              private readonly storage: ZbSecureStorageService,
              private readonly menuService: MkadMenuManagerService,
              private readonly userCustomer: MkadUserCustomersService,
              private route: Router,
              private scrollControl: MkadScrollControlService) {
    super(translate);
    this.initIconLibraryConfig();
    this.initDefaultLang();
    this.handlerTokenChange();
    this.meta.updateTag({name: 'theme-color', content: 'rgb(206,225,251)'});
    this.handlerMenuEvents();
    this.loadMapSource();
    this.authService.isAuthenticated().subscribe({
      next: (authenticated) => {
        if (!authenticated) this.handleInactiveSession();
      },
    });
  }

  ngAfterViewInit(): void {
    if (this.container) {
      this.scrollControl.layout = this.container;
    }
  }

  /**
   * @description
   * Method that listen the click on nebular menu
   * @private
   */
  private handlerMenuEvents() {
    this.menuService.onClickLogout().pipe(
      switchMap(() => {
        this.isLoading = true;
        return this.authService.doLogout();
      }),
      tap(() => {
        this.showSuccess(this.getTranslate('zibanu.ui.logout.message.success'));
        this.isFirstTokenChange.set(true);
        this.preferences.userCustomer.set(null);
        this.preferences.changeToDefaultConfig();
        this.route.navigate(['home']).then();
        this.isLoading = false;
      }),
      catchError(() => {
        return EMPTY;
      }),
    ).subscribe();

    this.menuService.onClickUserSetting().subscribe(() => this.openUserProfileDialog());
    this.menuService.onClickAppSetting().subscribe(() => this.openAppSettings());
    this.menuService.onClickAboutUs().subscribe(() => this.openAboutUsDialog());
    this.menuService.onClickChangePassword().subscribe(() => this.openChangePasswordDialog());
  }

  /**
   * @description
   * Method that open the user preferences dialog
   * @private
   */
  private openAppSettings(): void {
    this.dialog.open(AppSettingsComponent).onClose.subscribe({
      next: (response) => {
        this.closeSettingDialog(response);
        if (response && !response.save && this.preferences.appSettings === undefined && navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            ({coords}) => {
              this.preferences.centerMap.set([coords.latitude, coords.longitude]);
            },
            (error) => {
              console.log(error);
              this.preferences.centerMap.set([DEFAULT_MAP_LATITUDE, DEFAULT_MAP_LONGITUDE]);
            },
          );
        }
      },
    });
  }

  /**
   * @description
   * Method that open the user preferences dialog
   * @private
   */
  private openUserProfileDialog() {
    this.dialog.open(UserProfileComponent).onClose.subscribe({
      next: (response) => this.closeSettingDialog(response),
    });
  }

  /**
   * @description
   * Method that open the about us dialog
   * @private
   */
  openAboutUsDialog() {
    this.dialog.open(MkadAboutUsComponent);
  }

  /**
   * @description
   * Method that opens the mode if you are logged in or are a guest
   */
  handleOpenAboutUs() {
    if(this.isSessionActive){
      const keyModal='showModal';
      if(!sessionStorage.getItem(keyModal)) {
        this.openAboutUsDialog();
        sessionStorage.setItem(keyModal, 'true');
      }
    }else{
      this.openAboutUsDialog();
    }
  }

  /**
   * @description
   * Method that updates the token
   * @param response
   */
  closeSettingDialog(response: { save: boolean }) {
    if (response && response.save) {
      this.isLoading = true;
      this.refreshUserToken();
    }
  }

  /**
   * @description
   * Method that updates the user token
   */
  refreshUserToken() {
    this.authService.getToken()
      .pipe(
        filter(nbToken => nbToken.isValid()),
        switchMap(nbToken => this.authService.refreshToken({token: nbToken.getValue()})),
        tap(() => this.isLoading = false),
        catchError((error) => {
          this.handleErrorResponse(error);
          return of(error);
        }),
      )
      .subscribe();
  }

  /**
   * @description
   * Method that open the change password dialog
   * @private
   */
  private openChangePasswordDialog() {
    this.dialog.open(ZbChangePasswordComponent);
  }

  /**
   * @description
   * Method that responds to the OnInit lifecycle
   */
  override ngOnInit() {
    super.ngOnInit();
    window.onload = () =>{ this.handleOpenAboutUs();}
  }

  private handleInvalidSession(): void {
    this.userLevel = UserLevelEnum.Guest;
    this.menuService.reloadMainMenu(this.userLevel);
    this.getAvatarData(undefined);
    this.isLoading = false;
  }

  private handleValidSession(user: any): void {
    console.log(user);
    this.userLevel = user.level ?? UserLevelEnum.Guest;
    this.userName = user.full_name ?? undefined;
    this.preferences.lastlogin = user.last_login ?? undefined;
    this.preferences.username = user.username ?? undefined;
    this.preferences.level = user.level ?? undefined;

  }

  private handlerUserProfile() {
    return this.preferences.getProfileData().pipe(
      tap(userProfileResponse => {
        if (userProfileResponse) {
          const {email, first_name, last_name, profile} = userProfileResponse;
          this.preferences.userSettings = {
            email,
            first_name,
            last_name,
            ...profile,
          };
          console.log(profile);
          const avatar = profile ? profile.avatar : undefined;
          if (this.isFirstTokenChange() && profile && profile.app_settings === null) {
            this.isFirstTokenChange.set(false);
            this.openAppSettings();
          }
          this.preferences.changeUserConfig();
          this.menuService.reloadMainMenu(this.userLevel);
          this.getAvatarData(avatar);
        } else {
          this.preferences.changeToDefaultConfig();
        }
      }),
      switchMap(() => {
        return this.userCustomer.getCustomer();
      }),
      tap(response => {
        console.log('Response from userCustomer:', response);
        this.preferences.userCustomer.set(response);
      }),
      finalize(() => {
        this.isLoading = false;
      }),
    );
  }

  /**
   * @description
   * Method that subscribes to token exchange
   * @private
   */
  private handlerTokenChange(): void {
    this.authService.onChangeToken()
      .pipe(
        switchMap(tokenResponse => {
          console.log(tokenResponse);
          this.isSessionActive = tokenResponse.isValid();
          if (!this.isSessionActive) {
            this.handleInvalidSession();
            return of({avatar: undefined});
          }

          const {user} = tokenResponse.getPayload();
          this.handleValidSession(user);
          return this.handlerUserProfile();
        }),
        catchError(errorResponse => {
          this.isLoading = false;
          return of(errorResponse);
        }),
      )
      .subscribe();
  }

  /**
   * @description
   * Method handling the inactive session
   * @private
   */
  private handleInactiveSession(): void {
    const {hasSessionStored} = this.storage.getUserCredentials();
    if (hasSessionStored) {
      this.authService.doStoredLogin().subscribe({
        next: (storeResponse) => {
          console.log('login desde el storage', storeResponse);
          this.isLoading = false;
        },
        error: (response) => {
          this.showError(response.error.errors);
          this.isLoading = false;
        },
      });
    }
  }

  /**
   * @description
   * Method that gets the avatar from the profile
   */
  getAvatarData(_avatar: string | undefined) {
    this.userAvatar = _avatar;
  }

  /**
   * @description
   * Method that init the library config
   */
  initIconLibraryConfig(): void {
    this.iconLibrary.registerFontPack('fak', {packClass: 'fak', iconClassPrefix: 'fa'});
    this.iconLibrary.registerFontPack('far', {packClass: 'far', iconClassPrefix: 'fa'});
    this.iconLibrary.registerFontPack('fab', {packClass: 'fab', iconClassPrefix: 'fa'});
    this.iconLibrary.setDefaultPack('far');
  }

  /**
   * @description
   * Method that init the ngx-translate config
   */
  initDefaultLang(): void {
    const browserLangList: string[] = ['es', 'en'];
    this.translate.addLangs(browserLangList);
    this.translate.setDefaultLang(AllowedLanguagesEnum.en);
    this.translate.use(this.translate.getBrowserLang() ?? AllowedLanguagesEnum.es);
  }

  /**
   * @description
   * Method that load the local path of icons
   */
  loadMapSource() {
    Marker.prototype.options.icon = icon({...EXTRA_ICON_CONFIG});
  }

  /**
   * @description
   * Method that responds to the current language of the application
   */
  getCurrentLang(): LanguageAvailableType {
    return this.translate.currentLang as LanguageAvailableType;
  }

  hasPerpetualSession() {
    return this.preferences.userSettings.keep_logged_in ?? false;
  }

  /**
   * @description
   * Method that responds to clicking on the header avatar
   */
  onClickAvatar(): void {
    this.dialog.open(ZbLoginComponent);
  }

  get userCustomerData() {
    return this.preferences.userCustomer();
  }

  get userEmail(): string {
    return this.preferences.userSettings.email ?? '';
  }

  get dueDateAlert(): boolean {
    const customerData = this.preferences.userCustomer();
    if (!customerData?.contract_end_at) return false;
    if (!customerData.contract_end_at.includes('-')) return false;
    const today: Date = new Date();
    const expiredDate: Date = new Date(customerData.contract_end_at);
    const timeDifference: number = expiredDate.getTime() - today.getTime();
    const daysDifference: number = Math.ceil(timeDifference / fiftyDaysInSeconds);
    return daysDifference <= 15;
  }

}
