<section class="flex flex-column gap-3">
  <mkad-carousel
    [cardList]="cardListCustomer"
    [headerTitle]="'app.setting.panel.admin.customer.title' | translate"/>
  <mkad-carousel
    [cardList]="cardListDevice"
    [headerTitle]="'app.setting.panel.admin.device.title' | translate"/>
  <mkad-carousel
    [cardList]="cardListSystem"
    [headerTitle]="'app.setting.panel.admin.system.title' | translate"/>
</section>
